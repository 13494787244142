
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Checkbox from "primevue/checkbox";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  formatInputFloat,
  formatInputNumberOnly,
  parseInputFloatToFloat
} from "@/app/infrastructures/misc/Utils";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { FormProgressiveCommissionConfiguration } from "@/domain/entities/ProgressiveCommissionConfiguration";
import { ProgressiveCommissionConfigurationController } from "@/app/ui/controllers/ProgressiveCommissionConfigurationController";
import { SubmitProgressiveCommissionConfigurationRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";

@Options({
  components: {
    DetailLayout,
    Tooltip,
    Checkbox
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class ProgressiveCommissionConfigurationForm extends Vue {
  mounted() {
    this.initFormProgressiveCommission();
  }

  loadingDetail = false;
  get errorDetail() {
    return {
      isError: ProgressiveCommissionConfigurationController.isError,
      errorType: ProgressiveCommissionConfigurationController.errorCause
    };
  }
  get detailCommissionProgressive() {
    return ProgressiveCommissionConfigurationController.progressiveCommissionDetail;
  }
  async fetchDetailCommissionProgressiveConfiguration() {
    this.loadingDetail = true;
    const response = await ProgressiveCommissionConfigurationController.getProgressiveCommissionDetail(
      Number(this.$route.params.id)
    );
    this.loadingDetail = false;
    if (response) {
      this.form = new FormProgressiveCommissionConfiguration({
        id: this.detailCommissionProgressive.programId,
        startDate: this.$moment(
          this.detailCommissionProgressive.startDate
        ).toDate(),
        endDate: this.$moment(
          this.detailCommissionProgressive.endDate
        ).toDate(),
        termsAndConditions: this.detailCommissionProgressive.termCondition,
        commissionAppliedTo: this.detailCommissionProgressive.programUsedFor,
        commissionShipmentPrefix: this.detailCommissionProgressive
          .programPrefix,
        commissionParameter: {
          value: this.detailCommissionProgressive.parameterCalculation.join(
            ","
          ),
          name:
            this.optionParameter.find(
              (option: any) =>
                option.value ===
                this.detailCommissionProgressive.parameterCalculation.join(",")
            )?.name || ""
        },
        milestones: this.detailCommissionProgressive.mileStones.map(
          (item: any) => {
            return {
              idMilestone: item.id,
              milestone: this.formatMilestone(item.limit.toString()),
              commission: this.formatCommission(item.commission.toString())
            };
          }
        )
      });
    }
  }

  initFormProgressiveCommission() {
    if (this.isFormTypeAdd) {
      this.form = new FormProgressiveCommissionConfiguration();
    } else {
      this.fetchDetailCommissionProgressiveConfiguration();
    }
  }

  form = new FormProgressiveCommissionConfiguration();
  addMilestones() {
    this.form.milestones.push({
      milestone: "",
      commission: ""
    });
  }
  removeMilestones(index: number) {
    this.form.milestones.splice(index, 1);
  }
  formatMilestone(value: string) {
    return formatInputNumberOnly(value);
  }
  isErrorMilestone(index: number) {
    if (index === 0) {
      return {
        error: false
      };
    }
    return {
      error:
        parseInputFloatToFloat(this.form.milestones[index].milestone) <=
        parseInputFloatToFloat(this.form.milestones[index - 1].milestone),
      errorCaption: `Harus lebih besar dari batas akhir milestone sebelumnya`
    };
  }
  formatCommission(value: string) {
    const maxValue = 100;
    if (parseInputFloatToFloat(value) > maxValue) {
      return value.substring(0, value.length - 1);
    }
    return formatInputFloat(value);
  }
  isErrorCommission(index: number) {
    return (
      parseInputFloatToFloat(this.form.milestones[index].commission) < 0 ||
      parseInputFloatToFloat(this.form.milestones[index].commission) > 100
    );
  }

  get datePickerAttributes() {
    return {
      minDate: this.isFormTypeAdd
        ? this.$moment()
            .add("1", "days")
            .toDate()
        : this.$moment(this.form.startDate)
            .add("1", "days")
            .toDate()
    };
  }

  get titlePage() {
    return this.isFormTypeAdd ? "Buat Program" : "Ubah Program";
  }
  get isFormTypeAdd() {
    return this.$route.meta.formType === "add";
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    let path = "/tarif/commission-configuration/progressive-commisson";
    if (!this.isFormTypeAdd) {
      path += `/${this.$route.params.id}`;
    }
    this.$router.push(path);
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  onSaveForm() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.isFormTypeAdd
          ? "Simpan Informasi Program Baru?"
          : "Simpan Perubahan Terbaru?",
        message: this.isFormTypeAdd
          ? "Program baru yang Anda buat akan tersimpan di Aplikasi"
          : "Perubahan yang Anda berikan akan tersimpan di Aplikasi",
        textCancel: "Tidak",
        textSuccess: "Ya",
        onSubmit: this.isFormTypeAdd
          ? () => this.submitCreateNewProgram()
          : () => this.submitEditProgram(),
        onClose: () => MainAppController.closeMessageModal(),
        image: "leaving-page-illustration"
      })
    );
  }
  async submitCreateNewProgram() {
    MainAppController.closeMessageModal();
    MainAppController.showLoading();
    const response = await ProgressiveCommissionConfigurationController.addProgressiveCommissionConfiguration(
      new SubmitProgressiveCommissionConfigurationRequest({
        startDate: this.form.parsedStartDateToString(),
        endDate: this.form.parsedEndDateToString(),
        termsAndConditions: this.form.termsAndConditions,
        bookingAppliedTo: this.form.parsedAppliedTo(),
        bookingShipmentPrefix: this.form.parsedPrefix(),
        quantifier: this.form.commissionParameter.value,
        tierCalculation: this.form.milestones.map(
          (item: any, index: number) => {
            return {
              ...item,
              idMilestone: index + 1
            };
          }
        )
      })
    );
    MainAppController.closeLoading();
    if (response) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Pembuatan Program Berhasil !",
          message:
            "Program Komisi Progressive yang Anda buat berhasil tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    }
  }
  async submitEditProgram() {
    MainAppController.closeMessageModal();
    MainAppController.showLoading();
    const response = await ProgressiveCommissionConfigurationController.editProgressiveCommissionConfiguration(
      new SubmitProgressiveCommissionConfigurationRequest({
        id: this.form.id,
        startDate: this.form.parsedStartDateToString(),
        endDate: this.form.parsedEndDateToString(),
        termsAndConditions: this.form.termsAndConditions,
        bookingAppliedTo: this.form.parsedAppliedTo(),
        bookingShipmentPrefix: this.form.parsedPrefix(),
        quantifier: this.form.commissionParameter.value,
        tierCalculation: this.form.milestones
      })
    );
    MainAppController.closeLoading();
    if (response) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Perubahan Program Berhasil",
          message:
            "Program Komisi Progressive yang Anda ubah berhasil tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    }
  }
  get isMilestonesValid() {
    for (const index in this.form.milestones) {
      if (
        !this.form.milestones[index].milestone ||
        !this.form.milestones[index].commission
      ) {
        return false;
      }
      if (
        this.isErrorMilestone(Number(index)).error ||
        this.isErrorCommission(Number(index))
      ) {
        return false;
      }
    }
    return true;
  }
  get isFormValid() {
    const validateForm =
      !!this.form.startDate &&
      !!this.form.endDate &&
      !!this.form.termsAndConditions &&
      !!this.form.commissionParameter.value &&
      this.isMilestonesValid;
    if (this.isFormTypeAdd) return validateForm;
    return (
      validateForm && this.detailCommissionProgressive.status === "waiting"
    );
  }

  // commission applied to
  onChangeCommissionAppliedTo() {
    if (this.form.commissionAppliedTo.includes("shipment")) {
      this.form.commissionShipmentPrefix = this.availableShipmentPrefix;
    } else {
      this.form.commissionShipmentPrefix = [];
    }
  }
  onChangeCommissionShipmentPrefix() {
    if (this.form.commissionShipmentPrefix.length) {
      if (!this.form.commissionAppliedTo.includes("shipment")) {
        this.form.commissionAppliedTo = [
          "shipment",
          ...this.form.commissionAppliedTo
        ];
      }
    } else {
      this.form.commissionAppliedTo = this.form.commissionAppliedTo.filter(
        (appliedTo: string) => appliedTo !== "shipment"
      );
    }
  }
  get availableShipmentPrefix() {
    return [
      "AG",
      "AI",
      "DO",
      "TKP01",
      "AD",
      "ACA",
      "T1",
      "AO",
      "ACB",
      "TKLP",
      "AP",
      "ACR",
      "B1",
      "AS",
      "CCR",
      "B2",
      "TKP"
    ];
  }

  // commission parameter
  optionParameter = [
    { name: "Publish Rate", value: "publish_rate" },
    {
      name: "Publish Rate + Shipping Surcharge",
      value: "publish_rate,shipping_surcharge"
    },
    {
      name: "Publish Rate + Shipping Surcharge + Origin Forward Rate",
      value: "publish_rate,shipping_surcharge,origin_forward_rate"
    },
    {
      name: "Publish Rate + Shipping Surcharge + Destination Forward Rate",
      value: "publish_rate,shipping_surcharge,destination_forward_rate"
    },
    {
      name:
        "Publish Rate + Shipping Surcharge + Origin Forward Rate + Destination Forward Rate",
      value:
        "publish_rate,shipping_surcharge,origin_forward_rate,destination_forward_rate"
    }
  ];
  selectParameter(item: any) {
    if (this.form.commissionParameter.value === item.value) {
      this.form.commissionParameter = {
        name: "",
        value: ""
      };
    } else {
      this.form.commissionParameter = item;
    }
  }

  get ableToAddMilestones() {
    if (this.isFormTypeAdd) {
      return this.form.milestones.length < 5;
    }
    return (
      this.form.milestones.length < 5 &&
      this.detailCommissionProgressive.status === "waiting"
    );
  }
  get ableToRemoveMilestones() {
    if (this.isFormTypeAdd) {
      return this.form.milestones.length > 1;
    }
    return (
      this.form.milestones.length > 1 &&
      this.detailCommissionProgressive.status === "waiting"
    );
  }
}
